import React from "react";

const Statistics = ({ stats, description, title }) => {
    return (
        <div className="statistics">
            <div className="statistics__grid">
                <div className="statistics__grid-text">
                    <h2 className="text-h2 text-fw-medium text-clr-primary ">
                        {title ? `${title}` : "Statistics"}
                    </h2>
                    {description && (
                        <p className="text-p2 text-fw-medium text-clr-secondary ">
                            Vital statistics that keep us ticking.
                        </p>
                    )}
                </div>
                <div className="statistics__grid-content">
                    {stats.map((s, i) => (
                        <div key={i} className="statistics__grid-content-item">
                            <div className="statistics__grid-content-item__value engineering-aus">
                                {s.value}
                            </div>
                            <div className="statistics__grid-content-item__name">{s.name}</div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Statistics;
