import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const SalesforceExpertise = () => {
    return (
        <div className="partners-grid">
            <div className="partner-logo">
                <StaticImage
                    src="../images/salesforce/application.png"
                    placeholder="blurred"
                    alt="clients"
                    className="gap-05x"
                    loading="lazy"
                />
                <p className="tex-p3 text-clr-primary text-fw-medium">Application Architect</p>
            </div>
            <div className="partner-logo">
                <StaticImage
                    src="../images/salesforce/admin.png"
                    placeholder="blurred"
                    alt="clients"
                    className="gap-05x"
                    loading="lazy"
                />
                <p className="tex-p3 text-clr-primary text-fw-medium">Administrator</p>
            </div>
            <div className="partner-logo">
                <StaticImage
                    src="../images/salesforce/app-builder.png"
                    placeholder="blurred"
                    alt="clients"
                    className="gap-05x"
                    loading="lazy"
                />
                <p className="tex-p3 text-clr-primary text-fw-medium">Platform App Builder</p>
            </div>
            <div className="partner-logo">
                <StaticImage
                    src="../images/salesforce/cpq.png"
                    placeholder="blurred"
                    alt="clients"
                    className="gap-05x"
                    loading="lazy"
                />
                <p className="tex-p3 text-clr-primary text-fw-medium">CPQ Specialist</p>
            </div>
            <div className="partner-logo">
                <StaticImage
                    src="../images/salesforce/data.png"
                    placeholder="blurred"
                    alt="clients"
                    className="gap-05x"
                    loading="lazy"
                />
                <p className="tex-p3 text-clr-primary text-fw-medium">Data Architect</p>
            </div>
            <div className="partner-logo">
                <StaticImage
                    src="../images/salesforce/experience-cloud.png"
                    placeholder="blurred"
                    alt="clients"
                    className="gap-05x"
                    loading="lazy"
                />
                <p className="tex-p3 text-clr-primary text-fw-medium">
                    Experience Cloud Consultant
                </p>
            </div>
            <div className="partner-logo">
                <StaticImage
                    src="../images/salesforce/javascript.png"
                    placeholder="blurred"
                    alt="clients"
                    className="gap-05x"
                    loading="lazy"
                />
                <p className="tex-p3 text-clr-primary text-fw-medium">JavaScript Developer 1</p>
            </div>
            <div className="partner-logo">
                <StaticImage
                    src="../images/salesforce/pardot.png"
                    placeholder="blurred"
                    alt="clients"
                    className="gap-05x"
                    loading="lazy"
                />
                <p className="tex-p3 text-clr-primary text-fw-medium">Pardot Specialist</p>
            </div>
            <div className="partner-logo">
                <StaticImage
                    src="../images/salesforce/sales-cloud.png"
                    placeholder="blurred"
                    alt="clients"
                    className="gap-05x"
                    loading="lazy"
                />
                <p className="tex-p3 text-clr-primary text-fw-medium">Sales Cloud Consultant</p>
            </div>
            <div className="partner-logo">
                <StaticImage
                    src="../images/salesforce/service-cloud.png"
                    placeholder="blurred"
                    alt="clients"
                    className="gap-05x"
                    loading="lazy"
                />
                <p className="tex-p3 text-clr-primary text-fw-medium">Service Cloud Consultant</p>
            </div>
            <div className="partner-logo">
                <StaticImage
                    src="../images/salesforce/visibility-architect.png"
                    placeholder="blurred"
                    alt="clients"
                    className="gap-05x"
                    loading="lazy"
                />
                <p className="tex-p3 text-clr-primary text-fw-medium">
                    Sharing and Visibility Architect
                </p>
            </div>
        </div>
    );
};

export default SalesforceExpertise;
