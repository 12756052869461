import React from "react";
import scrollTo from "gatsby-plugin-smoothscroll";
const SalesforceContactBlock = () => {
    return (
        <div>
            <h2 className="text-h2 text-fw-medium gap-2x">
                Learn more about Sedin’s Salesforce capabilities, credibility, certification and
                endorsements
            </h2>
            <a onClick={() => scrollTo("#campaign-form")} className="sdn-cta-btn">
                Explore
            </a>
        </div>
    );
};

export default SalesforceContactBlock;
