import React from "react";
import { Navigation, EffectFade, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import ArrowIcon from "../components/ArrowIcon";
const ImageSlider = () => {
    return (
        <div className="testimonial-container">
            <div className="container">
                <Swiper
                    modules={[Navigation, EffectFade, Autoplay]}
                    slidesPerView={1.5}
                    navigation={{
                        nextEl: ".next-testimonial",
                        prevEl: ".prev-testimonial",
                    }}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    breakpoints={{
                        360: {
                            // width: 576,
                            slidesPerView: 1,
                        },
                        768: {
                            // width: 768,
                            slidesPerView: 1.5,
                        },
                    }}
                >
                    <SwiperSlide>
                        <div className="testimonial-item">
                            <div>
                                <StaticImage
                                    src="../images/machine-1.png"
                                    alt="img"
                                    loading="lazy"
                                />
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="testimonial-item">
                            <div>
                                <StaticImage
                                    src="../images/machine-2.png"
                                    alt="img"
                                    loading="lazy"
                                />
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="testimonial-item">
                            <div>
                                <StaticImage
                                    src="../images/machine-3.png"
                                    alt="img"
                                    loading="lazy"
                                />
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="testimonial-item">
                            <div>
                                <StaticImage
                                    src="../images/machine-4.png"
                                    alt="img"
                                    loading="lazy"
                                />
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="testimonial-item">
                            <div>
                                <StaticImage
                                    src="../images/machine-5.png"
                                    alt="img"
                                    loading="lazy"
                                />
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="testimonial-item">
                            <div>
                                <StaticImage
                                    src="../images/machine-6.png"
                                    alt="img"
                                    loading="lazy"
                                />
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="testimonial-item">
                            <div>
                                <StaticImage
                                    src="../images/machine-7.png"
                                    alt="img"
                                    loading="lazy"
                                />
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="testimonial-item">
                            <div>
                                <StaticImage
                                    src="../images/machine-8.png"
                                    alt="img"
                                    loading="lazy"
                                />
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="testimonial-item">
                            <div>
                                <StaticImage
                                    src="../images/machine-9.png"
                                    alt="img"
                                    loading="lazy"
                                />
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="testimonial-item">
                            <div>
                                <StaticImage
                                    src="../images/machine-10.png"
                                    alt="img"
                                    loading="lazy"
                                />
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="testimonial-item">
                            <div>
                                <StaticImage
                                    src="../images/machine-11.png"
                                    alt="img"
                                    loading="lazy"
                                />
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="testimonial-item">
                            <div>
                                <StaticImage
                                    src="../images/machine-12.png"
                                    alt="img"
                                    loading="lazy"
                                />
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="testimonial-item">
                            <div>
                                <StaticImage
                                    src="../images/machine-13.png"
                                    alt="img"
                                    loading="lazy"
                                />
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>
                <div className="testimonial-arrows">
                    <span className="prev-testimonial">
                        <ArrowIcon direction="left" />
                    </span>
                    <span className="next-testimonial">
                        <ArrowIcon direction="right" />
                    </span>
                </div>
            </div>
        </div>
    );
};

export default ImageSlider;
