import React from "react";
import { useState } from "react";
import UpArrow from "../images/uparrow.svg";
import DownArrow from "../images/downarrow.svg";

const Faq = ({ faq, engineering }) => {
    const [selected, setSelected] = useState(null);
    const toggle = (i) => {
        if (selected === i) {
            return setSelected(null);
        }
        setSelected(i);
    };
    return (
        <>
            <div className="sedin-faq-wrapper">
                <div className="sedin-faq__grid">
                    <div className="sedin-faq__grid-item">
                        {engineering ? (
                            <h2 className="text-h2 text-fw-medium text-clr-primary">FAQs</h2>
                        ) : (
                            <h2 className="text-h1 text-fw-medium text-clr-primary">FAQs</h2>
                        )}
                    </div>
                    <div className="sedin-faq__grid-item">
                        {faq.map((node, i) => {
                            return (
                                <div
                                    className="accordian  gap-05x"
                                    key={i}
                                    onClick={(e) => toggle(i)}
                                >
                                    <div className="accordian-header flex flex-jc-sb flex-ai-c ">
                                        <h4 className="sedin-faq__grid__title  text-p2 text-fw-medium text-primary-clr">
                                            {node.title}
                                        </h4>
                                        {selected === i ? (
                                            <img src={UpArrow} alt="upArrow" />
                                        ) : (
                                            <img src={DownArrow} alt="downArrow" />
                                        )}
                                    </div>

                                    <div
                                        className={
                                            selected === i
                                                ? "sedin-faq__grid__description text-clr-secondary show"
                                                : "sedin-faq__grid__description text-clr-secondary"
                                        }
                                    >
                                        <p>{node.description.description}</p>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Faq;
