import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
const SingleTestimonial = ({ testimonial }) => {
    return (
        <div className="tesimonial-wrapper">
            <div className="testimonial-item">
                <div className="testimonial-clientimage">
                    <GatsbyImage
                        image={testimonial[0].profileImage.gatsbyImageData}
                        alt={testimonial[0].profileImage.title}
                    />
                </div>
                <div className="testimonial-body">
                    {testimonial[0].testimonialStatement.testimonialStatement}
                </div>
                <div className="testimonial-footer">
                    <div className="name">{testimonial[0].personName}</div>
                    <div className="designation">{testimonial[0].personDesignation}</div>
                </div>
            </div>
        </div>
    );
};

export default SingleTestimonial;
