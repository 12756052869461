import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import DashboardImage from "../images/dashboard.png";
const SalesforceInfo = () => {
    return (
        <div className="salesforce-info">
            <div className="salesforce-info__grid">
                <div className="salesforce-info__grid-image">
                    <StaticImage src="../images/dashboard.png" alt="dashboard" />
                    <StaticImage src="../images/dashboard-2.png" alt="dashboard-marketing" />
                </div>
                <div className="salesforce-info__grid-content">
                    <h2 className="text-h2 gap-1x text-fw-medium">
                        Take the hard work out of data analysis and regular reporting requirements.
                    </h2>
                    <p className="text-p3 text-clr-secondary gap-1x">
                        Automated reporting capabilities at the push of a button.
                    </p>
                    <p className="text-p3 text-clr-secondary gap-1x">
                        Custom designed dashboards take the pain out of management reporting, giving
                        you access to specific reporting at the click of a mouse.
                    </p>
                    <ul>
                        <li className="text-p1 text-fw-medium gap-1x">
                            Custom report formatting
                            <p className="text-p3 text-fw-regular text-clr-secondary">
                                Reports to meet specific business expectations.
                            </p>
                        </li>

                        <li className="text-p1 text-fw-medium gap-1x">
                            Real time update
                            <p className="text-p3 text-fw-regular text-clr-secondary">
                                Always available, always current
                            </p>
                        </li>
                        <li className="text-p1 text-fw-medium gap-1x">
                            Automated inbox delivery
                            <p className="text-p3 text-fw-regular text-clr-secondary">
                                Never miss a reporting deadline again
                            </p>
                        </li>
                        <li className="text-p1 text-fw-medium ">
                            Leverage services cloud
                            <p className="text-p3 text-fw-regular text-clr-secondary">
                                Operational data dashboards for maintenance
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default SalesforceInfo;
