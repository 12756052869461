import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import scrollTo from "gatsby-plugin-smoothscroll";
const ServiceLocation = ({
    description,
    logo,
    name,
    designation,
    locationImage,
    engineeringCad,
    engineeringSlug,
    campaignMembers,
    handleModel,
    steelStructure,
    salesforce,
    industricalAutomation,
}) => {
    // const isMobile = window.innerWidth <= 767;
    return (
        <div className="service-location">
            <div className="service-location__grid">
                <div className="service-location__grid-item">
                    <h2 className="gap-1x text-h2 text-fw-medium">Meet our local team</h2>
                    <p className="gap-1x text-p3 text-fw-regular">{description}</p>
                    {/* {engineeringCad && (
                        <div className="service-location__grid-info gap-1x">
                            <GatsbyImage image={logo.gatsbyImageData} alt={logo.title} />
                            <div>
                                <p className="text-p3 text-fw-medium">{name}</p>
                                <p className="text-clr-secondary text-fw-regular">{designation}</p>
                            </div>
                        </div>
                    )} */}
                    {/* <a onClick={() => scrollTo("#campaign-form")} className="sdn-cta-btn ">
                        Contact us
                    </a> */}
                    {(steelStructure || engineeringSlug || industricalAutomation) ===
                    !engineeringCad ? (
                        <button onClick={handleModel} className="sdn-cta-btn engineering-aus">
                            Talk to us
                        </button>
                    ) : (
                        <button onClick={() => scrollTo("#campaign-form")} className="sdn-cta-btn ">
                            Contact us
                        </button>
                    )}
                </div>
                {(steelStructure || engineeringSlug || industricalAutomation) ===
                !engineeringCad ? (
                    <div>
                        {campaignMembers?.map((member, i) => (
                            <div className="leaders__grid-item" key={i}>
                                <GatsbyImage
                                    image={member.colorLogo?.gatsbyImageData}
                                    alt={member.colorLogo?.title}
                                    className="leader-image"
                                    loading="lazy"
                                />
                                <p className="text-p1 leader-name text-clr-primary text-fw-medium">
                                    {member.name}
                                </p>
                                <p className="text-p4 text-clr-secondary text-fw-regular">
                                    {member.designation}
                                </p>
                            </div>
                        ))}
                    </div>
                ) : (
                    <div>
                        <GatsbyImage
                            image={locationImage.gatsbyImageData}
                            alt={locationImage.title}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default ServiceLocation;
