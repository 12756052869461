import React from "react";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { navigate } from "gatsby";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { INLINES, BLOCKS, MARKS } from "@contentful/rich-text-types";
import { useState, useRef } from "react";
import Spinner from "./Spinner";
// import industrialVideo from "../images/cad-service/industrial-hero-video.mp4";

import axios from "axios";

const FormBanner = ({
    title,
    description,
    logo,
    formTitle,
    locationSlug,
    engineeringSlug,
    campaignDescPoints,
    engineeringCad,
    steelStructure,
    eamCampaginPage,
    industricalAutomation,
    handleModel,
}) => {
    const options = {
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,

            [BLOCKS.OL_LIST]: (node, children) => <ol>{children}</ol>,
            [BLOCKS.UL_LIST]: (node, children) => {
                return (
                    <ul className="text-p3 text-clr-secondary" style={{ paddingTop: "20px" }}>
                        {children}
                    </ul>
                );
            },
            [BLOCKS.LIST_ITEM]: (node, children) => (
                <li className="text-p3  text-clr-secondary text-fw-regular cad-li">{children}</li>
            ),
        },
    };
    const formAPIURL = `${process.env.GATSBY_CAMPAIGN_URL}`;

    const [formSubmitError, setSubmitError] = useState("");
    const [isLoading, setLoading] = useState(false);
    const formEl = useRef(null);

    const [stepForm, setFormState] = useState({
        name: "",
        // lastname: "",
        email: "",
        phone: "",
        // company: "",
        message: "",
    });
    const [isSubmitted, setSubmit] = useState(false);
    const [validationErrors, setValidationError] = useState({
        nameError: "",
        emailError: "",
        phoneError: "",
        // companyError: "",
    });

    function inputsHandler(e) {
        setFormState((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));
    }

    function formValidator() {
        let nameError = "";
        let emailError = "";
        let phoneError = "";
        let companyError = "";

        if (!/^[a-zA-Z ]+$/.test(stepForm.name)) nameError = "Invalid Name";
        // if (!/^[a-zA-Z ]+$/.test(stepForm.company)) companyError = "Invalid Company Name";
        if (
            !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                stepForm.email
            )
        )
            emailError = "Invalid Email";
        if (!/^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(stepForm.phone))
            phoneError = "Invalid Phone number";
        if (!stepForm.name) nameError = "Name is required";
        if (!stepForm.email) emailError = "Email is required";
        // if (!stepForm.company) companyError = "Company Name is required";
        if (!stepForm.phone) phoneError = "Phone number is required";

        if (nameError || emailError || phoneError || companyError) {
            setValidationError({ nameError, emailError, phoneError, companyError });
            return false;
        }
        return true;
    }

    function handleFormSubmit(event) {
        event.preventDefault();
        setSubmit(true);
        const isValid = formValidator();
        if (isValid) {
            setLoading(true);
            // reset validation errors
            setValidationError({
                nameError: "",
                emailError: "",
                phoneError: "",
            });
            setSubmitError("");
            const formdata = stepForm;
            axios
                .post(formAPIURL, formdata, {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                })
                .then((resp) => {
                    setSubmit(false);
                    setFormState({
                        name: "",
                        email: "",
                        phone: "",
                        message: "",
                    });
                    setLoading(false);
                    navigate("/contact/thank-you/");
                })
                .catch((err) => {
                    setSubmit(false);
                    setLoading(false);
                    setSubmitError("Oops, Something went wrong. Please try again");
                });
        }
    }

    return (
        <>
            <div className="form-banner" id="campaign-form">
                <div className="form-banner__text">
                    {(steelStructure && engineeringSlug && industricalAutomation) !==
                    (!engineeringCad && !locationSlug && !eamCampaginPage) ? (
                        <span></span>
                    ) : (
                        <div className="form-banner__logo gap-2x">
                            {logo.gatsbyImageData ? (
                                <GatsbyImage image={logo?.gatsbyImageData} alt={logo.title} />
                            ) : (
                                <img src={logo?.file.url} alt={logo.title} />
                            )}
                        </div>
                    )}
                    {(steelStructure && engineeringSlug && industricalAutomation) !==
                    (!engineeringCad && !locationSlug && !eamCampaginPage) ? (
                        <h1 className="text-h1 text-fw-strong gap-1x engineering-title">
                            Outsource <br />
                            {title}
                        </h1>
                    ) : (
                        <h1 className="text-h1 text-clr-white text-fw-medium gap-1x">{title}</h1>
                    )}
                    {(steelStructure && engineeringSlug && industricalAutomation) !==
                        !engineeringCad &&
                    !locationSlug &&
                    !eamCampaginPage ? (
                        <p className="text-p3  text-clr-secondary text-fw-regular gap-2x">
                            {description}
                            {renderRichText(campaignDescPoints, options)}
                        </p>
                    ) : (
                        <p className="text-p3 text-clr-white gap-1x">{description}</p>
                    )}
                    {(steelStructure || engineeringSlug || industricalAutomation) ===
                        engineeringCad && (
                        <p className="text-p3">
                            {locationSlug
                                ? "Contact us to request a free demonstration today."
                                : "Complete the form and a member of our local team will be in touch."}
                        </p>
                    )}
                    {steelStructure ? (
                        <button className="sdn-cta-btn next steel-structure" type="submit">
                            <span className="text-fw-bold" onClick={handleModel}>
                                Talk to our CAD Experts
                            </span>
                            <Spinner loading={isLoading} />
                        </button>
                    ) : (
                        ""
                    )}
                </div>
                {steelStructure ? (
                    <div>
                        <div className="cad-service-hero-sec-grid__right">
                            <div className="cad-service-hero-sec-grid__right-image">
                                <StaticImage
                                    src="../images/cad-service/cad-hero-two.png"
                                    // src="../images/cad-service/steel-structure-hero-img.png"
                                    alt="cad-service-hero-img"
                                    loading="lazy"
                                />
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="form-banner__form">
                        {(steelStructure && engineeringSlug) !== !engineeringCad &&
                        !locationSlug &&
                        !eamCampaginPage ? (
                            <div className="text-p1 text-clr-primary gap-05x text-fw-medium">
                                Get a FREE quote: Fill out the form and we will contact you shortly
                            </div>
                        ) : (
                            <div className="text-p1 text-clr-primary gap-05x text-fw-medium">
                                {renderRichText(formTitle, options)}
                            </div>
                        )}
                        <form
                            onSubmit={(e) => handleFormSubmit(e)}
                            ref={formEl}
                            noValidate
                            autoComplete="off"
                            // className="cad-service-landing-form"
                        >
                            <div className="input-row">
                                <div className="mat-group">
                                    <input
                                        type="text"
                                        className={`${
                                            validationErrors.nameError && isSubmitted ? "error" : ""
                                        }`}
                                        name="name"
                                        required
                                        autoComplete="off"
                                        onChange={(e) => inputsHandler(e)}
                                        value={stepForm.name}
                                    />
                                    <span className="highlight"></span>
                                    <span className="bar"></span>
                                    <label htmlFor="name">Name*</label>
                                    <div className="error-resp">{validationErrors.nameError}</div>
                                </div>
                                <div className="mat-group">
                                    <input
                                        type="text"
                                        className={`${
                                            validationErrors.emailError && isSubmitted
                                                ? "error"
                                                : ""
                                        }`}
                                        name="email"
                                        onChange={(e) => inputsHandler(e)}
                                        required
                                        autoComplete="off"
                                        value={stepForm.email}
                                    />
                                    <span className="highlight"></span>
                                    <span className="bar"></span>
                                    <label htmlFor="email">Business Email*</label>
                                    <div className="error-resp">{validationErrors.emailError}</div>
                                </div>

                                <div className="mat-group">
                                    <input
                                        type="text"
                                        className={`${
                                            validationErrors.phoneError && isSubmitted
                                                ? "error"
                                                : ""
                                        }`}
                                        name="phone"
                                        onChange={(e) => inputsHandler(e)}
                                        required
                                        autoComplete="off"
                                        value={stepForm.phone}
                                    />
                                    <span className="highlight"></span>
                                    <span className="bar"></span>
                                    <label htmlFor="email">Phone number*</label>
                                    <div className="error-resp">{validationErrors.phoneError}</div>
                                </div>

                                <div className="mat-group">
                                    <textarea
                                        type="text"
                                        name="message"
                                        rows={5}
                                        required
                                        onChange={(e) => inputsHandler(e)}
                                        autoComplete="off"
                                        value={stepForm.message}
                                    />
                                    <span className="highlight"></span>
                                    <span className="bar"></span>
                                    <label htmlFor="name">Message</label>
                                    <div className="error-resp"></div>
                                </div>
                            </div>
                            {(steelStructure && engineeringSlug) !== !engineeringCad &&
                            !locationSlug &&
                            !eamCampaginPage ? (
                                <button className="sdn-cta-btn next engineering" type="submit">
                                    <span className="text-fw-bold">Talk to our CAD Experts</span>
                                    <Spinner loading={isLoading} />
                                </button>
                            ) : (
                                <button className="sdn-cta-btn next" type="submit">
                                    <span>Submit</span>
                                    <Spinner loading={isLoading} />
                                </button>
                            )}
                        </form>
                    </div>
                )}
            </div>
        </>
    );
};

export default FormBanner;
