import React from "react";
import Lottie from "react-lottie";
import * as Emerging from "../animations/Emerging.json";
import { Link } from "gatsby";
import scrollTo from "gatsby-plugin-smoothscroll";
import { StaticImage } from "gatsby-plugin-image";

const NavigateBlockV3 = ({ title, engineering, engineeringCad, steelStructure, handleModel }) => {
    return (
        <div className="navigateV3-block">
            <div className="navigateV3-block__text">
                <h1 className="text-h2 text-fw-medium gap-2x">{title}</h1>
                {(steelStructure || engineering) == !engineeringCad ? (
                    <button
                        // onClick={() => scrollTo("#campaign-form")}
                        onClick={handleModel}
                        className="sdn-cta-btn engineering-aus"
                    >
                        {/* <span className="text-p1">Contact us</span> */}
                        Contact us
                    </button>
                ) : (
                    <button onClick={() => scrollTo("#campaign-form")} className="sdn-cta-btn">
                        Contact us
                    </button>
                )}
            </div>
            {engineering == !engineeringCad ? (
                <div className="navigateV3-block__animation">
                    <StaticImage
                        src="../images/engineering/turbine-1.png"
                        alt="img"
                        loading="lazy"
                        className="turbine-img"
                    />
                </div>
            ) : (
                <div className="navigateV3-block__animation">
                    <Lottie
                        options={{
                            loop: true,
                            autoplay: true,
                            animationData: Emerging,
                            rendererSettings: {
                                preserveAspectRatio: "xMidYMid slice",
                            },
                        }}
                    />
                </div>
            )}
        </div>
    );
};

export default NavigateBlockV3;
