import React, { useRef } from "react";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { BLOCKS } from "@contentful/rich-text-types";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import EngineeringImgSlider from "./EngineeringImgSlider";
import CadServiceGrid from "./Cad-ServiceGrid";
import SteelDesign from "../images/cad-service/Steel-1.svg";
import SteelDetails from "../images/cad-service/steel-detailing-1.svg";
import Fabrication from "../images/cad-service/Fabrication-1.svg";
import MechanicalDesign from "../images/cad-service/mechanical-1.svg";
import EletricalDesign from "../images/cad-service/electrical-1.svg";
import PLCHMI from "../images/cad-service/PLC.svg";
import Robot from "../images/cad-service/robot-1.svg";
import industrialVideo from "../images/cad-service/industrial-hero-video.mp4";
import Digitalization from "../images/cad-service/Digitilization-1.svg";
import SheetMetal from "../images/cad-service/Metal-1.svg";
import ThreeDCad from "../images/cad-service/3d-cad.svg";
import Detailing from "../images/cad-service/design.svg";
// import SteelStructureVideo from "../images/cad-service/steel-structure-video.mp4";
// import VideoArrowGif from "../images/cad-service/cad-industrial-arrow.gif";
import VideoArrow from "../images/cad-service/industrial-cad-arrow.svg";
import SteelStructureVideo from "../images/cad-service/steel-structure-video-1.mp4";

const CampaignInfo = ({
    title,
    logo,
    list,
    engineering,
    engineeringCad,
    steelStructure,
    industricalAutomation,
}) => {
    const ref = useRef();
    const options = {
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => {
                return <p className="text-p3 text-clr-secondary">{children}</p>;
            },
            [BLOCKS.UL_LIST]: (node, children) => {
                return <ul className="text-p3 text-clr-secondary">{children}</ul>;
            },
            [BLOCKS.OL_LIST]: (node, children) => <ol>{children}</ol>,
            [BLOCKS.LIST_ITEM]: (node, children) => <li>{children}</li>,
        },
    };
    return (
        <>
            <div className="campaign-info">
                {engineering != engineeringCad ? (
                    <h2 className="text-h1 campaign-info__text text-fw-medium">CAD {title}</h2>
                ) : (
                    <h2 className="text-h1 campaign-info__text text-fw-medium">{title}</h2>
                )}
                {steelStructure || industricalAutomation ? (
                    <>
                        {steelStructure ? (
                            <div className="cad-service-sec__grid-item">
                                <div className="cad-service-sec__grid-item__left">
                                    <div className="cad-service-sec__grid-item__content gap-2x">
                                        <div className="cad-service-sec__grid-item__content-image">
                                            <img src={SteelDesign} alt="Steel Structure" />
                                        </div>
                                        <div className="cad-service-sec__grid-item__content-details">
                                            <h3 className="text-h3 title text-clr-primary text-fw-medium gap-05x">
                                                Steel Structural Design
                                            </h3>
                                            <p className="text-p4 title text-clr-secondary">
                                                Quality is our hallmark. Our team chooses the right
                                                structural elements including steel channel, slabs,
                                                platforms, beams, columns, type, grade, and material
                                                size, etc. for every part of the structure, thus
                                                ensuring the safety and stability of the structures.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="cad-service-sec__grid-item__content gap-2x">
                                        <div className="cad-service-sec__grid-item__content-image">
                                            <img src={SteelDetails} alt="Steel Structure" />
                                        </div>
                                        <div className="cad-service-sec__grid-item__content-details">
                                            <h3 className="text-h3 title text-clr-primary text-fw-medium gap-05x">
                                                Structural Steel Detailing
                                            </h3>
                                            <p className="text-p4 title text-clr-secondary">
                                                We design, create & detail structures efficiently
                                                for all your steel projects. From structural
                                                drafting, 3D modeling to structural steel detailing,
                                                our team of structural engineers collaborates
                                                closely with construction companies to create
                                                designs using CAD platforms.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="cad-service-sec__grid-item__content gap-2x">
                                        <div className="cad-service-sec__grid-item__content-image">
                                            <img src={Fabrication} alt="Steel Structure" />
                                        </div>
                                        <div className="cad-service-sec__grid-item__content-details">
                                            <h3 className="text-h3 title text-clr-primary text-fw-medium gap-05x">
                                                Fabrication Drawings
                                            </h3>
                                            <p className="text-p4 title text-clr-secondary">
                                                Our team translates engineering plans into precise
                                                technical drawings, serving as blueprints for
                                                fabricators, erectors, and contractors. These
                                                detailed drawings ensure the fabrication and
                                                assembly of complex steel structures with utmost
                                                precision and efficiency.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="cad-service-sec__grid-item__right">
                                    <div className="cad-service-sec__grid-item__right-steelvideo">
                                        <video
                                            loop
                                            autoPlay
                                            muted
                                            playsInline
                                            controlsList="nodownload"
                                            className="steel-structure-video gap-2x"
                                        >
                                            <source src={SteelStructureVideo} type="video/mp4" />
                                        </video>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div>
                                <div className="cad-service-sec__grid-item">
                                    <div className="cad-service-sec__grid-item__content">
                                        <div className="cad-service-sec__grid-item__content-image">
                                            <img src={MechanicalDesign} alt="Steel Structure" />
                                        </div>
                                        <div className="cad-service-sec__grid-item__content-details">
                                            <h3 className="text-h3 title text-clr-primary text-fw-medium gap-05x">
                                                Mechanical Design Conception
                                            </h3>
                                            <p className="text-p4 title text-clr-secondary">
                                                We design comprehensive solutions to various
                                                industrial applications to optimize the
                                                manufacturing operations, improved performance,
                                                reduced human error through DFM techniques, and
                                                improve safety right from conception.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="cad-service-sec__grid-item__content">
                                        <div className="cad-service-sec__grid-item__content-image">
                                            <img src={EletricalDesign} alt="Steel Structure" />
                                        </div>
                                        <div className="cad-service-sec__grid-item__content-details">
                                            <h3 className="text-h3 title text-clr-primary text-fw-medium gap-05x">
                                                Electrical Control Designs
                                            </h3>
                                            <p className="text-p4 title text-clr-secondary">
                                                Our electrtical control engineers ensure that the
                                                machine design meets optimal performance standards
                                                and seamlessly integrates with the machine through
                                                the electrical design.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="cad-service-sec__grid-item__content">
                                        <div className="cad-service-sec__grid-item__content-image">
                                            <img src={PLCHMI} alt="Steel Structure" />
                                        </div>
                                        <div className="cad-service-sec__grid-item__content-details">
                                            <h3 className="text-h3 title text-clr-primary text-fw-medium gap-05x">
                                                PLC/HMI/SCADA Programming
                                            </h3>
                                            <p className="text-p4 title text-clr-secondary">
                                                We provide customized PLC, HMI, SCADA programming
                                                (OLP) as per industry standards, ensuring every
                                                process is validated precisely for the seamless
                                                integration with the machine thus provides optimal
                                                performance & safety.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="cad-service-sec__grid-item__content">
                                        <div className="cad-service-sec__grid-item__content-image">
                                            <img src={Robot} alt="Steel Structure" />
                                        </div>
                                        <div className="cad-service-sec__grid-item__content-details">
                                            <h3 className="text-h3 title text-clr-primary text-fw-medium gap-05x">
                                                Robotic Simulation
                                            </h3>
                                            <p className="text-p4 title text-clr-secondary">
                                                Our Robotic Simulation service offers kinematic
                                                validation in design, robot reach analysis,
                                                selection and verification of robotic peripherals,
                                                as well as ergonomics and safety assessment. We
                                                ensure collision-free path planning and deliver
                                                high-fidelity Offline Programming (OLP) to reduce
                                                cycle time, enabling accurate and seamless
                                                integration with real robots.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="cad-service-sec__grid-item__industrial-videodemo">
                                        <h2 className="text-h1 text-fw-medium gap-4x">
                                            A Sample Industrial Automation Video
                                        </h2>
                                        <div className="cad-service-sec__grid-item__industrial-videodemo__sec">
                                            <video
                                                loop
                                                autoPlay
                                                muted
                                                playsInline
                                                controls
                                                controlsList="nodownload"
                                                className="industrial-video"
                                                height={500}
                                            >
                                                <source src={industrialVideo} type="video/mp4" />
                                            </video>
                                            <div className="play-btn"></div>
                                        </div>
                                        <div ref={ref}>
                                            <img src={VideoArrow} alt="gif" className="arrow-gif" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </>
                ) : engineering != engineeringCad ? (
                    // <CadServiceGrid />
                    <div>
                        <div className="cad-service-sec__grid-item">
                            <div className="cad-service-sec__grid-item__content">
                                <div className="cad-service-sec__grid-item__content-image">
                                    <img src={Digitalization} alt="Steel Structure" />
                                </div>
                                <div className="cad-service-sec__grid-item__content-details">
                                    <h3 className="text-h3 title text-clr-primary text-fw-medium gap-05x">
                                        Digitization / 2D-3D Conversion
                                    </h3>
                                    <p className="text-p4 title text-clr-secondary">
                                        Struggling with paper archives? Digitize your legacy
                                        drawings, scanned images, PDF, TIFF, and hand drawn drafts
                                        into precise CAD formats with our expert 2D/3D conversion
                                        services. Our skilled CAD team ensures accurate CAD
                                        migrations with data security.
                                    </p>
                                </div>
                            </div>
                            <div className="cad-service-sec__grid-item__content">
                                <div className="cad-service-sec__grid-item__content-image">
                                    <img src={SheetMetal} alt="Steel Structure" />
                                </div>
                                <div className="cad-service-sec__grid-item__content-details">
                                    <h3 className="text-h3 title text-clr-primary text-fw-medium gap-05x">
                                        Sheet Metal Design
                                    </h3>
                                    <p className="text-p4 title text-clr-secondary">
                                        Leverage Sedin's expertise in sheet metal design to address
                                        any manufacturing challenges directly. We create precise
                                        sheet metal parts, products, and assemblies, providing
                                        fabrication drawings tailored for CNC, Laser Jet, and Water
                                        Jet machines.
                                    </p>
                                </div>
                            </div>

                            <div className="cad-service-sec__grid-item__content">
                                <div className="cad-service-sec__grid-item__content-image">
                                    <img src={ThreeDCad} alt="Steel Structure" />
                                </div>
                                <div className="cad-service-sec__grid-item__content-details">
                                    <h3 className="text-h3 title text-clr-primary text-fw-medium gap-05x">
                                        3D Modelling & Drafting
                                    </h3>
                                    <p className="text-p4 title text-clr-secondary">
                                        Precision defines us. We provide parametric feature-based 3D
                                        solid modeling, assembling, freeform or surface modeling for
                                        your product design upgrades. We perform the design
                                        validation and verification in 3D and 2D.
                                    </p>
                                </div>
                            </div>
                            <div className="cad-service-sec__grid-item__content">
                                <div className="cad-service-sec__grid-item__content-image">
                                    <img src={Detailing} alt="Steel Structure" />
                                </div>
                                <div className="cad-service-sec__grid-item__content-details">
                                    <h3 className="text-h3 title text-clr-primary text-fw-medium gap-05x">
                                        New Product Development
                                    </h3>
                                    <p className="text-p4 title text-clr-secondary">
                                        Our Engineers expertise in bringing your New Product ideas
                                        to life. We specialize in Innovative concept development and
                                        detailed design. With advanced CAD tools and cutting edge
                                        technology, we deliver cost effective, high-quality, and
                                        easily manufacturable products efficiently.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="campaign-info__grid">
                        <div className="campaign-info__grid-item">
                            {renderRichText(list, options)}
                        </div>
                        <div className="campaign-info__grid-item">
                            <GatsbyImage
                                className="info-image"
                                image={logo.gatsbyImageData}
                                alt={logo.title}
                                loading="lazy"
                            />
                        </div>
                    </div>
                )}
                {/* {engineering != engineeringCad ? (
                    <CadServiceGrid initialCategory="Structural Engineering" />
                ) : (
                    <div className="campaign-info__grid">
                        <div className="campaign-info__grid-item">
                            {renderRichText(list, options)}
                        </div>
                        <div className="campaign-info__grid-item">
                            <GatsbyImage
                                class="info-image"
                                image={logo.gatsbyImageData}
                                alt={logo.title}
                                loading="lazy"
                            />
                        </div>
                    </div>
                )} */}
            </div>
        </>
    );
};

export default CampaignInfo;
